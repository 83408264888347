import React, { useState, useRef, useEffect } from "react";
import { IoMdArrowDropdown } from "react-icons/io";
import RotatingIcon from "./RotatingIcon";
import { Link } from "react-router-dom";
import { RxHamburgerMenu } from "react-icons/rx";

export default function MobileNavbar() {
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [innerActiveDropdown, setInnerActiveDropdown] = useState(null);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const dropdownRefs = useRef({});
  const [rotatedStates, setRotatedStates] = useState({});
  const [navbarOpen, setNavbarOpen] = useState(false);

  const handleNavbarToggle = () => {
    setNavbarOpen((prev) => !prev);
  };

  const mainNavHeaders = [
    { text: "What is Xertz", href: "/" },
    { text: "What we Craft", href: "/arena" },
    { text: "Careers", href: "/career" },
  ];

  const sanitizeDropdownName = (name) => {
    return name
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^a-z0-9-]/g, "");
  };

  const WhatIsXertz = [
    { text: "Who we Are ?", href: "/#who-we-are" },
    { text: "What we Do !", href: "/#what-we-do" },
    { text: "Our Mission", href: "/#mission" },
    { text: "Industrial Collaboration", href: "/#industrial-collaboration" },
  ];

  const WhatWeCraft = [
    { text: "3D Arena", href: "/arena#three-d-arena" },
    { text: "2D Arena", href: "/arena#two-d-arena" },
    { text: "AI Arena", href: "/arena#ai-arena" },
  ];


  const Careers = [
    { text: "About Us", href: "/career#about-our-company" },
    {
      title: "Career Opportunity",
      links: [
        {
          text: "Available Opportunity",
          href: "/career#available-opportunity",
        },
        { text: "Future Opportunity", href: "/career#future-opportunity" },
      ],
    },
  ];

  const toggleRotation = (id) => {
    setRotatedStates((prevState) => ({
      ...prevState,
      [id]: !prevState[id], // Toggle the specific icon's rotation state
    }));
  };

  const toggleDropdown = (menu) => {
    setActiveDropdown((prev) => (prev === menu ? null : menu));
  };

  const cancelDropdown = () => {
    setActiveDropdown(null);
    setRotatedStates({});
  };

  useEffect(() => {
    if (activeDropdown) {
      const element = dropdownRefs.current[activeDropdown];
      if (element) {
        const rect = element.getBoundingClientRect();
        const sanitizedClassName = sanitizeDropdownName(activeDropdown);
        const dropdown = document.querySelector(
          `.dropdown-${sanitizedClassName}`
        );

        if (dropdown) {
          const dropdownRect = dropdown.getBoundingClientRect();

          let top = rect.bottom + window.scrollY; // Adjust for page scroll
          let left = rect.left + window.scrollX; // Added window.scrollX for horizontal alignment

          // Adjust position if dropdown goes beyond the viewport on the right
          if (left + dropdownRect.width > window.innerWidth) {
            left = window.innerWidth - dropdownRect.width - 16; // 16px for padding
          }

          // Adjust position if dropdown goes beyond the viewport on the bottom
          if (top + dropdownRect.height > window.innerHeight + window.scrollY) {
            top = rect.top + window.scrollY - dropdownRect.height - 8; // 8px for padding
          }

          setDropdownPosition({ top, left });
        }
      }
    }
  }, [activeDropdown]); // Run only when `activeDropdown` changes

  return (
    <>
      <div className="relative lg:hidden flex flex-col justify-center items-start w-full h-full bg-blue-900 backdrop-blur-md">
        <div className="flex flex-row justify-between w-full items-center p-3 bg-blue-900">
          <div className="text-white text-xl font-varela">Xertz Studios</div>
          <button onClick={handleNavbarToggle} className="text-white text-3xl">
            <RxHamburgerMenu />
          </button>
        </div>
        {navbarOpen && (
          <div className="flex flex-col justify-center m-3 space-y-2 items-start">
            {mainNavHeaders.map((header, index) => (
              <div
                key={index}
                className="relative flex flex-row mx-2 items-center"
              >
                <Link
                  to={header.href}
                  ref={(el) => (dropdownRefs.current[header.text] = el)}
                  className="text-white text-lg mx-2 font-varela"
                >
                  {header.text}
                </Link>
                <button
                  onClick={() => toggleDropdown(header.text)}
                  className="flex justify-center items-center text-white text-xl"
                >
                  <RotatingIcon
                    id={`icon${index}`} // Unique ID based on index or header
                    rotated={rotatedStates[`icon${index}`]} // Pass the state for the icon
                    toggleRotation={() => toggleRotation(`icon${index}`)} // Toggle state for the icon
                  />
                </button>
              </div>
            ))}
          </div>
        )}
      </div>
      {activeDropdown && (
        <div
          className={`z-[9999] fixed mt-2 bg-blue-900 text-white w-48 rounded shadow-lg dropdown-${sanitizeDropdownName(
            activeDropdown
          )}`}
          style={{ top: dropdownPosition.top, left: dropdownPosition.left }}
        >
          {activeDropdown === "What is Xertz" && (
            <>
              {WhatIsXertz.map((item, index) => (
                <Link
                  key={index}
                  to={item.href}
                  className="block px-4 py-2 hover:border-b-2 rounded-xl hover:border-white duration-75 font-varela"
                  onClick={cancelDropdown}
                >
                  {item.text}
                </Link>
              ))}
            </>
          )}
          {activeDropdown === "What we Craft" && (
            <>
              {WhatWeCraft.map((item, index) => (
                <Link
                  key={index}
                  to={item.href}
                  className="block px-2 py-2 hover:border-b-2 rounded-xl hover:border-white duration-75 font-varela"
                  onClick={cancelDropdown}
                >
                  {item.text}
                </Link>
              ))}
            </>
          )}
          {activeDropdown === "Careers" && (
            <>
              {Careers.map((item, index) => (
                <div key={index} className="mt-2">
                  {item.title ? (
                    <>
                      <h3 className="px-2 py-1 text-lg font-bold">
                        {item.title}
                      </h3>
                      {item.links.map((link, i) => (
                        <Link
                          key={i}
                          to={link.href}
                          className="block px-2 py-2 hover:border-b-2 rounded-xl hover:border-white duration-75 font-varela animate-slideDown"
                          onClick={cancelDropdown}
                        >
                          {link.text}
                        </Link>
                      ))}
                    </>
                  ) : (
                    <Link
                      to={item.href}
                      className="block px-2 py-2 hover:border-b-2 rounded-xl hover:border-white duration-75 font-varela animate-slideDown"
                      onClick={cancelDropdown}
                    >
                      {item.text}
                    </Link>
                  )}
                </div>
              ))}
            </>
          )}
        </div>
      )}
    </>
  );
}
