// LogoCanvas.jsx
import React, { useEffect, useRef } from "react";

export default function LogoCanvas() {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    const keyword = "Xertz Studios";
    const radius = 20;
    const drag = 0.9;
    const ease = 0.2;
    const density = 1;
    const offset = 2;
    const timeout = 20;
    let pixels;
    let particles = [];
    let mx = 0,
      my = 0;

    function resizeCanvas() {
      canvas.width = 200;
      canvas.height = 80;
      initCanvas();
    }

    window.addEventListener("resize", resizeCanvas);

    function initCanvas() {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.font = `36px 'Orbitron'`;
      ctx.fillStyle = "#cccccc";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";

      ctx.fillText(keyword, canvas.width / 2, canvas.height / 2);

      particles = [];
      init();
    }

    canvas.addEventListener("mousemove", function (e) {
      const rect = canvas.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;

      // Normalize the mouse position relative to the canvas dimensions
      mx = (x / canvas.width) * 200; // Adjusting to 200px width
      my = (y / canvas.height) * 80; // Adjusting to 80px height
    });

    function Particle(x, y) {
      this.hx = x - offset * Math.random();
      this.hy = y - offset * Math.random();
      this.cx = canvas.width * Math.random();
      this.cy = canvas.height * Math.random();
      this.vx = Math.random() * 5 - 2.5;
      this.vy = Math.random() * 5 - 2.5;
    }

    Particle.prototype.update = function () {
      const dx = this.cx - mx;
      const dy = this.cy - my;
      const ds = dx * dx + dy * dy;
      const aradius = Math.min(radius / ds, radius);
      const theta = Math.atan2(dy, dx);

      const hdx = this.hx - this.cx;
      const hdy = this.hy - this.cy;
      const hds = Math.sqrt(hdx * hdx + hdy * hdy);
      const hf = hds * 0.01;
      const htheta = Math.atan2(hdy, hdx);

      this.vx += aradius * Math.cos(theta) + hf * Math.cos(htheta);
      this.vy += aradius * Math.sin(theta) + hf * Math.sin(htheta);

      this.vx *= drag;
      this.vy *= drag;

      this.cx += this.vx;
      this.cy += this.vy;
    };

    function draw() {
        // Correctly define `a` before using it
        var a = ctx.createImageData(canvas.width, canvas.height);
        var b = a.data; // Use `a` to get the data
      
        for (var i = 0; i < particles.length; i++) {
          particles[i].update();
        }
      
        for (var i = 0; i < particles.length; i++) {
          var p = particles[i];
          var n = (~~p.cx + ~~p.cy * canvas.width) * 4;
          b[n] = b[n + 1] = b[n + 2] = 255;
          b[n + 3] = 255;
        }
      
        ctx.putImageData(a, 0, 0);
      
        setTimeout(function () {
          requestAnimationFrame(draw);
        }, timeout);
      }

    function init() {
      pixels = ctx.getImageData(0, 0, canvas.width, canvas.height).data;

      for (let i = 0; i < canvas.height; i += density) {
        for (let j = 0; j < canvas.width; j += density) {
          const index = (j + i * canvas.width) * 4;

          if (pixels[index + 3] > 128) {
            if (index >= particles.length) {
              particles.push(new Particle(j, i));
            } else {
              particles[index].hx = j;
              particles[index].hy = i;
            }
          }
        }
      }
    }

    resizeCanvas();
    draw();

    // Cleanup function to remove event listeners
    return () => {
      window.removeEventListener("resize", resizeCanvas);
      canvas.removeEventListener("mousemove", () => {});
    };
  }, []);

  return (
    <canvas
      ref={canvasRef}
      id="logoparticleeffect"
      className="w-[200px] h-[80px] block"
    ></canvas>
  );
}
