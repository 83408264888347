import React, { useEffect } from "react";
import CareerOpportunities from "../components/CareerOpportunities";
import FutureOpportunities from "../components/FutureOpportunities";
import CareersIntro from "../components/CareersIntro";
import Testimonials from "../components/Testimonials";
import { useLocation } from "react-router-dom";

export default function Careers() {

  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <>
      <div id="about-our-company">
        <CareersIntro />
      </div>
      <div id="testimonials">
        <Testimonials />
      </div>
      <div id="available-opportunity">
        <CareerOpportunities />
      </div>
      <div id="future-opportunity">
        <FutureOpportunities />
      </div>
    </>
  );
}
