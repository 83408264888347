import React, { useState, useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// Register the ScrollTrigger plugin
gsap.registerPlugin(ScrollTrigger);

export default function WhoWeAre() {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    gsap.fromTo(
      ".who-heading",
      {
        opacity: 0,
        x: 200,
        y: -200,
        letterSpacing: 4,
      },
      {
        x: 0,
        y: 0,
        duration: 2,
        opacity: 1,
        letterSpacing: 0,
        ease: "bounce.out",
        scrollTrigger: {
          trigger: ".who-heading", // Trigger the animation when .who-heading comes into view
          start: "top 80%", // Start animation when the top of the element is 80% from the top of the viewport
          toggleActions: "play none none none",
        },
      }
    );

    gsap.fromTo(
      ".who-img",
      {
        opacity: 0,
        scale: 2,
        rotateX: -20,
        rotateY: -20,
        rotateZ: -20,
        x: 400,
        y: 400,
      },
      {
        duration: 1.5,
        opacity: 1,
        scale: 1,
        rotateX: 0,
        rotateY: 0,
        rotateZ: 0,
        x: 0,
        y: 0,
        ease: "circ.inOut",
        scrollTrigger: {
          trigger: ".who-img", // Trigger when image container comes into view
          start: "top 80%",
          toggleActions: "play none none none",
        },
      }
    );

    gsap.fromTo(
      ".who-text",
      {
        opacity: 0,
        scale: 0,
        transformOrigin: "top center",
      },
      {
        duration: 1.5,
        opacity: 1,
        scale: 1,
        ease: "circ.inOut",
        scrollTrigger: {
          trigger: ".who-text", // Trigger when text comes into view
          start: "top 80%",
          toggleActions: "play none none none",
        },
      }
    );

    const handleMouseMove = (e) => {
      const { clientX: x, clientY: y } = e;
      setMousePosition({ x, y });
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      ScrollTrigger.killAll(); // Clean up ScrollTriggers on component unmount
    };
  }, []);

  const { x, y } = mousePosition;
  const centerX = window.innerWidth / 2;
  const centerY = window.innerHeight / 2;
  const rotateY = ((x - centerX) / centerX) * 20;
  const rotateX = ((y - centerY) / centerY) * -20;

  return (
    <div className="flex flex-col justify-center items-center from-blue-300 via-blue-500 to-indigo-800 bg-gradient-to-b w-[100%] p-4 h-auto perspective-1000">
      <h6 className="who-heading text-white w-full p-3 text-4xl md:text-6xl font-varela">
        Who We Are ?
      </h6>
      <div className="flex flex-col md:flex-row  justify-center items-center space-y-4 md:space-y-0 md:space-x-4 w-[90%] md:w-[80%] h-full rounded-xl opacity-70 bg-white m-6 shadow-zinc-950 shadow-lg hover:shadow-xl transition-shadow duration-300 ease-in-out">
        <div
          className="who-img flex w-full md:w-[50%] h-full lg:ml-2"
          style={{
            transform: `rotateX(${rotateX}deg) rotateY(${rotateY}deg)`,
          }}
        >
          <img
            src="/images/a.jpg"
            alt=""
            className="w-full h-full object-cover rounded-t-xl md:rounded-xl"
          />
        </div>
        <div className="flex flex-col w-full md:w-[50%] p-4">
          <p className="who-text text-blue-900 text-lg md:text-2xl font-varela">
            Welcome to Xert Studios, where innovation meets excellence. We are
            your dedicated IT partner, blending cutting-edge technologies with
            visionary leadership to craft tailored solutions. Prioritizing
            quality over quantity, user experience over profits, and client
            satisfaction above all, Xert Studios delivers bespoke innovations
            that align perfectly with your objectives, ensuring every project is
            functional, creative, and impactful.
          </p>
          <a href = "/arena" className="flex justify-center items-center w-full md:w-[150px] text-nowrap font-varela h-[50px] md:h-[30px] text-lg font-bold m-[10px] text-blue-900 bg-white border-b-2 hover:border-2 duration-150 border-blue-900 rounded-xl p-2 md:p-4">
            Our Services
          </a>
        </div>
      </div>
    </div>
  );
}
