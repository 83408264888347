import React from "react";

const Card = () => {
  return (
    <div className="w-[90%] h-[500px] m-4 rounded-md bg-gradient-to-r from-[#1e3a8a] to-[#2563eb] flex flex-wrap gap-2 p-2">
      <CardItem
        title="Innovative Product Launches"
        description="Q1 2025: Launch our Application on various platforms to engage users with our craft of innovations. Q3 2025: Release a next-gen AI-powered predictive analytics tool designed to optimize business operations and enhance decision-making."
      />
      <CardItem
        title="Technological Expansion"
        description="Q4 2025: Implement IoT solutions in diverse industries, showcasing our ability to interconnect devices and systems for smarter operations."
      />
      <CardItem
        title="Global Reach and Collaboration"
        description="2024: Establish strategic partnerships with leading technology providers and research institutions to stay at the forefront of innovation. 2025: Expand our services to new international markets, building a global network of clients and collaborators."
      />
      <CardItem
        title="Excellence in Client Satisfaction"
        description="2024: Achieve a 95% client satisfaction rate through exceptional service delivery and personalized solutions. 2025: Implement a comprehensive feedback loop to continuously improve our offerings and adapt to evolving client needs."
      />
      <CardItem
        title="Sustainability and Social Impact"
        description="2024: Launch initiatives to reduce our carbon footprint, incorporating sustainable practices in our operations and project executions. 2025: Engage in community outreach programs, using our technology to support educational and social development initiatives."
      />
    </div>
  );
};

const CardItem = ({ title, description }) => {
  const descriptionParts = description
    .split(".")
    .map((part) => part.trim())
    .filter((part) => part.length > 0);

  return (
    <div className="group h-full flex-1 overflow-hidden cursor-pointer rounded-xl transition-all duration-500 bg-gradient-to-br from-blue-900 via-blue-500 to-blue-700  flex opacity-20 hover:opacity-100 justify-center items-center hover:flex-[20] lg:hover:flex-[4]">
      <div className="relative flex flex-col justify-center items-center min-w-[14em] min-h-[100%] p-6 text-center rotate-[-90deg] transition-transform duration-500 uppercase text-white tracking-[.1em] group-hover:rotate-0 font-varela">
        <div className="absolute inset-0  bg-gradient-to-br from-teal-500 to-blue-950 opacity-95 rounded-lg shadow-xl transition-shadow duration-300"></div>
        {/* Royal Blue to Purple gradient background */}

        <h2 className="relative z-10 text-[10px] lg:text-[16px] lg:text-3xl font-extrabold mb-2 text-shadow-lg transform transition-transform duration-300 group-hover:scale-105">
          <span className="text-outline"> {title} </span>{" "}
          {/* Decorative outline */}
        </h2>

        <div className="relative z-10 flex flex-col text-left p-2 lg:p-4">
          {descriptionParts.map((part, index) => (
            <p
              key={index}
              className="text-[12px] lg:text-base mb-2 whitespace-normal text-white opacity-90 hover:opacity-100 transition-opacity duration-300"
            >
              {part}
              {index < descriptionParts.length - 1 && "."}
            </p>
          ))}
        </div>

        <div className="relative z-10 mt-4 h-1 w-full bg-yellow-400 rounded-full shadow-md"></div>
        {/* Decorative accent line */}

        <style jsx>{`
          .text-shadow-lg {
            text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
          }
          .text-outline {
            color: white;
            text-shadow: 1px 1px 0 rgba(255, 165, 0, 0.7),
              /* Orange outline */ -1px -1px 0 rgba(255, 165, 0, 0.7),
              1px -1px 0 rgba(255, 165, 0, 0.7),
              -1px 1px 0 rgba(255, 165, 0, 0.7);
          }
        `}</style>
      </div>
    </div>
  );
};

export default Card;
