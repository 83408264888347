import React, { useEffect } from "react";
import { FaRocket, FaCog, FaGlobe, FaStar, FaLeaf } from "react-icons/fa";
import Card from "./Card";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default function Mission01() {
  useEffect(() => {
    // textPopUpTl animation using GSAP
    gsap.fromTo(
      ".mission-heading",
      {
        transform: "translateY(-50px) translateX(-50px)",
        textShadow: "0 1px 0 #cccccc, 0 2px 0 #cccccc, 0 3px 0 #cccccc, 0 4px 0 #cccccc, 0 5px 0 #cccccc, 0 6px 0 #cccccc, 0 7px 0 #cccccc, 0 8px 0 #cccccc, 0 9px 0 #cccccc, 0 50px 30px rgba(0, 0, 0, 0.3)",
        opacity: 0,
      },
      {
        transform: "translateY(0) translateX(0)",
        textShadow:
          "none",
        opacity: 1,
        duration: 0.5,
        ease: "cubic-bezier(0.250, 0.460, 0.450, 0.940)",
        scrollTrigger: {
          trigger: ".mission-heading",
          start: "top 80%",
          toggleActions: "play none none reverse",
        },
      }
    );

    // textFocusIn animation using GSAP
    gsap.fromTo(
      ".milestones-heading, .milestones-text",
      {
        filter: "blur(12px)",
        opacity: 0,
      },
      {
        filter: "blur(0px)",
        opacity: 1,
        duration: 2,
        ease: "cubic-bezier(0.550, 0.085, 0.680, 0.530)",
        scrollTrigger: {
          trigger: ".milestones-heading",
          start: "top 85%",
          toggleActions: "play none none reverse",
        },
      }
    );
  }, []);

  return (
    <div className="flex flex-col items-center bg-gradient-to-b from-blue-300 via-blue-500 to-indigo-800 w-[100%] h-auto ">
      {/* Mission Heading */}
      <h6 className="mission-heading text-white w-[100%] p-3 text-6xl font-varela">
        Mission
      </h6>

      {/* Mission Text */}
      <div className="w-full h-auto flex flex-col md:p-4">
        <p className="mission-text text-white text-lg md:text-xl p-4 font-medium leading-relaxed font-varela mb-12">
          At Xertz Studios, our mission is to revolutionize technology by
          transforming the ordinary into the extraordinary. We are dedicated to
          pushing the limits of innovation, creating solutions that combine
          creativity and precision to tackle complex challenges. Our aim is to
          lead change in the tech industry, offering groundbreaking applications
          and systems that surpass client expectations. By leveraging emerging
          technologies like AR, VR, AI, and Blockchain, we aim to redefine user
          engagement and operational efficiency, setting the pace with visionary
          ideas and unmatched expertise.
        </p>

        {/* Milestones Section */}
        <div className="flex flex-col justify-center items-center">
          <h6 className="milestones-heading text-white w-[100%] p-3 text-6xl font-varela">
            Milestones
          </h6>

          {/* Card Component */}
          <Card />
        </div>

        {/* Milestones Text */}
        <p className="milestones-text text-white text-lg md:text-xl p-4 font-medium leading-relaxed font-varela mb-12">
          At Xertz Studios, our mission and milestones are a testament to our
          dedication to excellence, innovation, and impactful technology. We are
          driven by a vision to shape the future and create lasting value for
          our clients and the world.
        </p>
      </div>
    </div>
  );
}
